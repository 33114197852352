ion-modal.hierarchy-modal {
  .modal-wrapper.sc-ion-modal-ios {
    top: 20px;
    width: 100%;
    height: calc(100% - 20px);
    position: absolute;
  }

  .modal-wrapper.sc-ion-modal-md {

    width: 100%;
    height: 100%;
  }

}

ion-title {
  text-align: center;
}

p-breadcrumb {
  .ui-breadcrumb {
    border-style: none !important;
    margin-bottom: 10px;
  }

  // .ui-menuitem {}

  .ui-menuitem-text {
    color: #6B6D6F;
    font-family: 'Gotham-Book';
    font-size: 16px;
  }

  .ui-breadcrumb-chevron {
    color: #751269 !important;
  }

}

p-tabview {
  .ui-tabview {

    .ui-tabview-selected {}

    .ui-tabview-nav {}

    .ui-state-active {}

    .ui-tabview-selected {
      background-color: #751269 !important;
    }
  }
}

p-autoComplete {

  .ui-autocomplete-token {
    background-color: #751269 !important;
  }
}

quill-editor {
  width: 100%;

  .ql-editor {
    height: 200px !important;

  }

}

p-multiselect {
  .ui-multiselect {
    width: 100%;
  }

  .ui-state-highlight {
    background-color: #751269 !important;
  }

  .ui-multiselect-item {
    display: inline-flex !important;
    width: 100%;
  }

}

p-sidebar {
  .ui-sidebar {
    background: rgba(0, 0, 0, 0.2) !important;
    padding: 0px !important;
    width: 100%;
  }
}

.wbs-category-alert {
  .alert-wrapper.sc-ion-alert-md {
    max-width: 70% !important;
    max-height: 70%;

    .alert-checkbox-group {
      max-height: none !important;
    }
  }
}

.searchbar-input-container.sc-ion-searchbar-ios {
  background: #ffffff !important;
}

.confirm-button-filter {
  text-align: center;
  padding: 20px;
  cursor: pointer;
}

.alert-wrapper {
  max-width: 290px !important;

  & .alert-message,
  .alert-sub-title {
    text-align: left;
  }
}