/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/primeng.min.css";

/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */
@import "./app/app.scss";
@import "./theme/mixins/app.mixins.scss";

// workaround: full screen action sheet scrolling background on iOS
.action-sheet-group.sc-ion-action-sheet-ios {
  overflow: auto;
}

// workaround: action sheet not honoring safe area margins
.action-sheet-wrapper.sc-ion-action-sheet-ios {
  top: 0;
  height: auto;
}

//reduce ion select size on ios mobile 
@media (max-width: 767px) {
  .action-sheet-button-inner.sc-ion-action-sheet-ios {
    font-size: 14px;
  }
}

ion-popover {
  --width: 240px !important;
}